import React from 'react'
import Link from 'gatsby-link'
import get from 'lodash/get'
import './style.scss'

class CardService extends React.Component {
  render() {
    const { site, data, isIndex } = this.props
    const title = get(data, 'frontmatter.title')
    const short = get(data, 'frontmatter.short')
    const path = get(data, 'frontmatter.path')
    const description = get(data, 'frontmatter.description')
    const color = get(data, 'frontmatter.color')
    const image = get(data, 'frontmatter.image')
    const serviceUrl = get(data, 'frontmatter.serviceUrl')
    const serviceUrlTitle = get(data, 'frontmatter.serviceUrlTitle')

    return (
      <div className="card-service">
        <div className="visible">
          <a className="head" href={path} style={{ backgroundColor: color }}>
            <h1 className="title">{title}</h1>
            <h3 className="short">{short}</h3>
          </a>
          <a className="logo-service" href={path} title={title} style={{ backgroundColor: color }}>
            <img className="thumb-service" src={image.publicURL} alt="image service" />
          </a>
          <a href={serviceUrl} title={title} className="link-service">
            <div className="link-service-content">
              <p className="service-title">
                <i className="fa fa-magic" aria-hidden="true"></i> {serviceUrlTitle}
              </p>
            </div>
          </a>
        </div>
        <div className="desc-service">
          <div className="content">
            <div className="page-description">
            {description}
            </div>
            <Link className="link-more" to={path}>
              En savoir +
            </Link>
          </div>
        </div>
      </div>
    )
  }
}

export default CardService
